import React from "react"

import { PageLayout } from "@components/layouts"
import Seo from "@components/seo"
import SingleColumnBlock from "@components/singleColumnBlock"
import GlobalQuickLinks from "@components/globalQuickLinks"

import {
  RequestMoreInfo,
} from "@components/Forms"

const TikTokPage = () => {
  return (
    <PageLayout>
      <Seo
        title="TikTok Follower Links | Websuasion"
        ogDescription="Quick links to things we've recently discussed on TikTok."
        image="/images/temp/1920x1080.jpg"
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h1>Quick Links</h1>
            <p>
              Thanks for visiting from TikTok. Here are some links to things we've been posting about.
            </p>
            <GlobalQuickLinks />
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h4>Got a question? Or want to give me a piece of your mind? All good, fill out the form.</h4>
            <RequestMoreInfo />
          </div>
        }
      />
    </PageLayout>
  )
}
export default TikTokPage
